/**
 * コードグループ
 */
export const CODE_GROUP = {
  EXEC: "EXEC",
  EXISTE: "EXISTE",
  ACTUAL: "ACTUAL",
  CHANGE: "CHANGE",
  CONFIR: "CONFIR",
  GAS: "GAS",
  MEAL: "MEAL",
  SALMIN: "SALMIN",
  VALID: "VALID",
  AUTHOR: "AUTHOR",
  WWS: "WWS",
  GASCAR: "GASCAR",
  CARESH: "CARESH",
  CAREKB: "CAREKB",
  CARETM: "CARETM",
  DIVISI: "DIVISI",
  APPRVL: "APPRVL",
  TZONE: "TZONE",
  FCAR: "FCAR",
  HJN: "HJN",
  CYCLE: "CYCLE",
  HLP: "HLP"
};

export const NOT_SET_CODE = {
  CHANGE: "99",
  EXISTE: "99"
};

export const BUSYO_CODE = {
  BUILDING: "1",
  FOOD: "2",
  WELFARE: "3",
  GENERAL: "9"
};

export const COMMON_SHIFT_PATTERNS_REF = "99999";

export const CARE_SHUBETSU = {
  EMERGENCY_FLAG: "000010",
  MIDNIGHT_TEL: "000011"
};
